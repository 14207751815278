import styles from "../../css/styles.module.css";
import ButtonDownload from "../buttons/buttonDownload";

export default function News() {
  const paragraphs = [];

  return (
    <div
      style={{
        display: "flex",
        flex: "1",
        minWidth: "500px",
        //backgroundColor: "yellow",
      }}
    >
      <div
        style={{
          paddingBottom: "20px",
          margin: "0",
          display: "flex",
          flexDirection: "row",
        }}
      ></div>
      <div
        className={styles.paragraphs}
        style={{
          whiteSpace: "wrap",
          color: "rgb(250,250,250)",
          marginBottom: "20px",
          overflow: "scroll",
          flexShrink: "0",
          minHeight: "0",
        }}
      >
        {paragraphs.map((d, i) => {
          return (
            <div key={i}>
              <h2>{d}</h2>
              <br />
            </div>
          );
        })}
      </div>
    </div>
  );
}
