import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Auth } from "./components/pages/auth.js";
import { Dashboard } from "./components/pages/dashboard.js";
import { PasswordReset } from "./components/pages/passwordReset.js";
import { ConfirmPasswordReset } from "./components/pages/confirmPasswordReset.js";
import { GridParent } from "./components/grid/gridParent.js";
import { useAuth } from "./hooks/authHook";

function MainApp() {
  const authed = useAuth();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
      }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />}></Route>
          {/* <Route path="/gridDemo" element={<GridParent />}></Route> */}
          <Route path="/auth" element={<Auth />}></Route>
          <Route path="/passwordReset" element={<PasswordReset />}></Route>
          <Route
            path="/confirmPasswordReset"
            element={<ConfirmPasswordReset />}
          ></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default MainApp;
