import React, { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { useAuth } from "../../hooks/authHook.js";
import { auth } from "../../index.js";
import { useNavigate } from "react-router-dom";
import styles from "../../css/styles.module.css";

export const Auth = () => {
  const authed = useAuth(false);
  const navigate = useNavigate();

  const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

  const [email, setEmail] = useState("");
  const [PW, setPW] = useState("");
  const [verifyPW, setVerifyPW] = useState("");
  const [registering, setRegistering] = useState(false);

  const [canLogin, setCanLogin] = useState(false);
  const [canRegister, setCanRegister] = useState(false);

  useEffect(() => {
    if (email && PW) setCanLogin(true);
    else setCanLogin(false);

    if (registering && passwordRegex.test(verifyPW) && PW === verifyPW)
      setCanRegister(true);
    else setCanRegister(false);
  }, [email, PW, verifyPW, registering]);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPW(e.target.value);
  const handleVerifyPW = (e) => setVerifyPW(e.target.value);

  const handleSignIn = () => {
    if (registering) {
      createUserWithEmailAndPassword(auth, email, PW)
        .then(() => navigate("/"))
        .catch((error) => alert("Registration failed."));
    } else {
      signInWithEmailAndPassword(auth, email, PW)
        .then(() => navigate("/"))
        .catch((error) => alert("Authentication failed."));
    }
  };

  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(() => navigate("/"))
      .catch((error) => alert(error.message));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSignIn();
    }
  };

  useEffect(() => {
    if (authed) navigate("/");
  }, [authed]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className={styles.mainBorder}
        style={{
          width: "440px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              margin: "20px",
              flex: "1",
            }}
          >
            <input
              type="email"
              placeholder="Email..."
              className={styles.mainInput}
              onChange={handleEmailChange}
              onKeyDown={handleKeyDown}
            />
            <input
              type="password"
              placeholder="Password..."
              className={styles.mainInput}
              onChange={handlePasswordChange}
              onKeyDown={handleKeyDown}
            />
            {registering && (
              <input
                type="password"
                placeholder="Verify Password..."
                className={styles.mainInput}
                onChange={handleVerifyPW}
                onKeyDown={handleKeyDown}
              />
            )}
          </div>
          <div
            style={{
              width: "60px",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <button
              style={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
              className={styles.mainButton}
              onClick={handleSignIn}
              disabled={!canLogin}
            >
              <img
                src="https://i.imgur.com/qaxtgq9.png"
                alt="OK"
                style={{
                  width: "100%",
                  imageRendering: "auto",
                  opacity: "0.2",
                }}
              />
            </button>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "3px",
            backgroundColor: "rgb(50,50,50)",
          }}
        ></div>
        <div
          style={{
            padding: "20px",
            display: "flex",
          }}
        >
          <button
            className={styles.mainButton}
            style={{
              width: "100%",
              height: "70px",
              padding: "20px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={handleGoogleSignIn}
          >
            <img
              src="https://i.imgur.com/bZ5zP7G.png"
              alt="Google logo"
              style={{
                width: "50px",
                marginRight: "10px",
                imageRendering: "auto",
              }}
            />
            <div style={{ width: "100%" }}>Sign in with Google</div>
          </button>
        </div>
      </div>
      <a
        style={{
          width: "100%",
          color: "rgba(255,255,255,0.5)",
          fontSize: "0.6em",
          fontWeight: "600",
          marginTop: "5px",
          marginLeft: "20px",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() => navigate(`/passwordReset?email=${email}`)}
      >
        Forgot Password?
      </a>
      <a
        style={{
          width: "100%",
          color: "rgba(255,255,255,0.5)",
          fontSize: "0.6em",
          fontWeight: "600",
          marginTop: "5px",
          marginLeft: "20px",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() => setRegistering(true)}
      >
        Register
      </a>
    </div>
  );
};
