import styles from "../../css/styles.module.css";

export default function Downloads() {
  return (
    <div
      style={{
        flex: "1",
        display: "flex",
        minWidth: "500px",
        justifyContent: "center",
        alignItems: "flex-end",
        //backgroundColor: "blue",
      }}
    >
      <button
        className={styles.mainBorder}
        style={{
          padding: "15px",
          paddingLeft: "30px",
          paddingRight: "30px",
          fontSize: "xx-large",
        }}
        onClick={() =>
          window.open(
            "https://www.mediafire.com/folder/mwtqonnzs0f6w/PlayPal",
            "rel=noopener noreferrer"
          )
        }
      >
        Download PlayPal
      </button>
    </div>
  );
}
