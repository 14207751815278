import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../index.js";
import { useAuth } from "../../hooks/authHook.js";
import { auth } from "../../index.js";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import styles from "../../css/styles.module.css";
import Ribbon from "../../assets/Ribbon.png";

import NewsTab from "../tabs/newsTab.js";
import DownloadsTab from "../tabs/downloadsTab.js";
import CreditTab from "../tabs/creditTab.js";

import Profile from "../tools/profile.js";
import Options from "../tools/options.js";

export const Dashboard = () => {
  const authed = useAuth(false);
  const [amount, setAmount] = useState(400);
  const [paying, setPaying] = useState(false);
  const [tab, setTab] = useState("News");
  const [options, setOptions] = useState(false);
  const [apiKey, setApiKey] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (db && authed) {
      const docRef = doc(db, "user-data", auth.currentUser.uid);
      const unsub = onSnapshot(docRef, (d) => {
        setApiKey(d.data().activeKey);
      });
      return unsub;
    }
  }, [authed]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/auth");
    } catch (error) {
      alert(error);
    }
  };

  const toggleOptions = () => {
    setOptions(!options);
  };

  function topButton() {
    if (authed) {
      return (
        <button
          style={{
            position: "fixed",
            top: "20px",
            right: "20px",
            height: "50px",
            width: "50px",
            backgroundColor: "rgba(0,0,0,0)",
          }}
          onClick={toggleOptions}
        >
          <span>
            <img
              style={{
                height: "100%",
                width: "100%",
                margin: "auto",
                display: "block",
              }}
              src="https://i.imgur.com/7TtL3DJ.png"
            ></img>
          </span>
        </button>
      );
    } else {
      return (
        <button
          style={{
            position: "fixed",
            top: "20px",
            right: "20px",
            height: "50px",
            width: "50px",
            backgroundColor: "rgba(0,0,0,0)",
          }}
          onClick={() => {
            navigate("/auth");
          }}
        >
          <span>
            <img
              style={{
                height: "100%",
                width: "100%",
                margin: "auto",
                display: "block",
              }}
              src="https://i.imgur.com/wTBVvhm.png"
            ></img>
          </span>
        </button>
      );
    }
  }

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          overflow: "hidden",
          height: "-moz-calc(100% - 300px)",
          height: "-webkit-calc(100% - 300px)",
          height: "calc(100% - 300px)",
          margin: "150px 0px",
          //backgroundColor: "purple",
        }}
      >
        {authed && <Profile uid={auth.currentUser.uid} />}
        {/*Options*/}
        {topButton()}
        {options && <Options apiKey={apiKey} handleSignOut={handleSignOut} />}
        {/*Main div*/}
        <NewsTab />
        <DownloadsTab />
        <CreditTab authed={authed} uid={authed ? auth.currentUser.uid : null} />
      </div>
    </div>
  );
};
