import { sendPasswordResetEmail } from "firebase/auth";
import { useState, useEffect } from "react";
import { useAuth } from "../../hooks/authHook.js";
import { auth } from "../../index.js";
import { useSearchParams } from "react-router-dom";
import Home from "../tools/home.js";
import styles from "../../css/styles.module.css";

export const PasswordReset = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [email, setEmail] = useState("");

  const [canLogin, setCanLogin] = useState(false);

  const [linkSent, setLinkSent] = useState(false);

  useEffect(() => {
    setEmail(searchParams.get("email"));
  }, []);

  useEffect(() => {
    setCanLogin(email);
  }, [email]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const requestPasswordChange = () => {
    if (auth && email) {
      sendPasswordResetEmail(auth, email);
      setLinkSent(true);
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Home />

      {!linkSent && (
        <div
          className={styles.mainBorder}
          style={{
            width: "550px",
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <input
            className={styles.mainInput}
            style={{ flex: "1" }}
            placeholder="Email..."
            value={email}
            onChange={handleEmailChange}
          />
          <button
            className={styles.mainButton}
            style={{
              padding: "10px",
              width: "150px",
            }}
            disabled={!canLogin}
            onClick={requestPasswordChange}
          >
            Send Link
          </button>
        </div>
      )}
      {linkSent && (
        <div
          className={styles.mainBorder}
          style={{
            width: "550px",
            padding: "20px",
            textAlign: "center",
          }}
        >
          Password reset link sent to email address
        </div>
      )}
    </div>
  );
};
