import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import ButtonPurchase from "../buttons/buttonPurchase";
import Payment from "../stripe/payment.js";
import styles from "../../css/styles.module.css";

export default function CreditTab(props) {
  const [cost, setCost] = useState(10);
  const [paying, setPaying] = useState(false);

  const navigate = useNavigate();

  const handleSetCost = (e) => {
    setCost(Math.max(Math.min(e.target.value, 500), 3));
  };

  const getAmount = () => {
    return cost * 50;
  };

  useEffect(() => {
    setPaying(false);
  }, [props]);

  const togglePaying = () => {
    if (props.authed) {
      if (paying) {
        setPaying(false);
      } else {
        setPaying(true);
      }
    } else {
      navigate("/auth");
    }
  };

  return (
    <div
      style={{
        flex: "1",
        display: "flex",
        minWidth: "500px",
        justifyContent: "flex-end",
        //backgroundColor: "red",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          justifyContent: "flex-end",
          //alignItems: "flex-end",
          width: "400px",
          maxWidth: "80vw",
          padding: "20px 40px",
        }}
      >
        {" "}
        <h1>Add credit to account</h1>
        <div
          className={styles.mainPadding}
          style={{
            width: "100%",
          }}
        />
        <div
          className={styles.mainBackground}
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "20px",
              gap: "10px",
            }}
          >
            $
            <input
              className={styles.mainInput}
              style={{
                width: "150px",
                paddingLeft: "15px",
                fontSize: "x-large",
              }}
              placeholder="..."
              type="number"
              defaultValue={cost}
              onChange={handleSetCost}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
            />
          </div>
          <div
            className={styles.mainPadding}
            style={{
              width: "100%",
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "20px",
              gap: "10px",
            }}
          >
            <img
              style={{
                width: "30px",
                height: "30px",
              }}
              src="https://i.imgur.com/UaJiKBh.png"
            />
            <h3 style={{ color: "rgb(250, 250, 250)" }}>{getAmount()}</h3>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "20px",
              gap: "10px",
            }}
          >
            <h3 style={{ paddingLeft: "40px" }}>${cost}</h3>
            <h3 style={{ paddingRight: "40px" }}>Subtotal</h3>
          </div>
        </div>
        <div
          className={styles.mainPadding}
          style={{
            width: "100%",
          }}
        />
        <button
          className={styles.mainButton}
          style={{ padding: "10px 0px" }}
          onClick={togglePaying}
        >
          Purchase
        </button>
      </div>
      {props.authed && props.uid != null && paying && (
        <div>
          <Payment
            amount={getAmount()}
            uid={props.uid}
            onFinished={() => setPaying(false)}
          />
        </div>
      )}
    </div>
  );
}
