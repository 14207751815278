import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import styles from "../../css/styles.module.css";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const getCost = () => {
    return props.amount / 0.5 / 100;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/dashboard`,
      },
      redirect: "if_required",
    });

    if (error) {
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setMessage("Payment status: " + paymentIntent.status);
      props.onFinished();
    } else {
      setMessage("Unexpected state");
    }

    setIsProcessing(false);
  };

  if (document.getElementById("payment-form"))
    document.getElementById("payment-form").scrollIntoView();

  return (
    <div>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "space-apart",
          alignItems: "center",
          //backgroundColor: "white",
        }}
        id="payment-form"
        onSubmit={handleSubmit}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <button
            className={styles.mainButton}
            style={{
              padding: "10px 15px",
              margin: "10px",
              marginLeft: "auto",
              fontSize: "medium",
            }}
            onClick={() => {
              props.onFinished();
            }}
          >
            X
          </button>
        </div>
        <div style={{ width: "90%" }}>
          <PaymentElement appearance={{ theme: "night" }} />
        </div>
        {message && (
          <div
            style={{
              padding: "5px",
              margin: "20px",
              color: "rgba(180,30,30)",
              fontWeight: "500",
              fontSize: "large",
            }}
            id="payment-message"
          >
            {message}
          </div>
        )}
        <button
          className={styles.mainButton}
          style={{ padding: "10px 15px", margin: "20px" }}
        >
          Pay ${getCost()} for {props.amount} credit
        </button>
      </form>
    </div>
  );
}
