import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkoutForm";
import Spinner from "../../assets/loading.gif";
import styles from "../../css/styles.module.css";

function Payment(props) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [initiatedPurchase, setInitiatedPurchase] = useState(false);

  const reqQuery = window.location.protocol === "https:" ? "" : "?demo=true";

  useEffect(() => {
    fetch("https://jordbugg.se/stripe/config" + reqQuery).then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    if (initiatedPurchase || !props.amount || !props.uid) return;
    setClientSecret("");
    setInitiatedPurchase(true);
    fetch("https://jordbugg.se/stripe/buyCredit" + reqQuery, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ amount: props.amount, userID: props.uid }),
    }).then(async (r) => {
      const { clientSecret } = await r.json();
      setClientSecret(clientSecret);
    });
  }, [props]);

  const stripeOptions = {
    clientSecret: clientSecret,
    appearance: {
      theme: "night",
    },
  };

  return (
    <div
      className={styles.mainBorder}
      style={{
        minHeight: "300px",
        width: "600px",
        position: "absolute",
        top: "50%",
        right: "50%",
        transform: "translate(50%, -50%)",
        maxWidth: "90vw",
        maxHeight: "90vh",
      }}
    >
      {!stripePromise && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            transform: "translate(50%, 50%)",
          }}
        >
          <img
            src={Spinner}
            style={{
              width: "50px",
              transform: "translate(-25px, -25px)",
              imageRendering: "auto",
            }}
          />
        </div>
      )}
      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={stripeOptions}>
          <CheckoutForm amount={props.amount} onFinished={props.onFinished} />
        </Elements>
      )}
    </div>
  );
}

export default Payment;
