import styles from "../../css/styles.module.css";

export default function Options(props) {
  return (
    <div
      className={styles.mainBorder}
      style={{
        position: "fixed",
        top: "90px",
        right: "20px",
        width: "400px",
        maxWidth: "90vw",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 6px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          overflow: "hidden",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                borderRadius: "0px",
                margin: "auto",
                textAlign: "center",
              }}
            >
              Key:
            </div>
            <input
              type="password"
              placeholder="..."
              value={props.apiKey}
              readOnly={true}
              className={styles.mainInput}
              style={{ flex: "1" }}
            ></input>
            <button
              onClick={() => {
                navigator.clipboard.writeText(props.apiKey);
              }}
              className={styles.mainButton}
              style={{ padding: "10px" }}
            >
              <img
                src="https://i.imgur.com/vh3DP0r.png"
                style={{
                  width: "30px",
                  imageRendering: "auto",
                  opacity: "0.9",
                }}
              ></img>
            </button>
          </div>
        </div>
        <div
          className={styles.mainPadding}
          style={{
            width: "100%",
          }}
        />
        <button
          className={styles.mainButton}
          style={{ padding: "10px", margin: "10px" }}
          onClick={() => {
            props.handleSignOut();
          }}
        >
          Log out
        </button>
      </div>
    </div>
  );
}
